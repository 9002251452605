<template>
  <div>
    <HeaderPanel
      title="Product Banner"
      :filter="filter"
      :hasDropdown="false"
      :hasFilter="false"
      :hasSearch="false"
      routerPath="/productbanner/details/0"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(image_url)="data">
              <div
                class="d-flex justify-content-center"
                v-if="data.item.is_video != 1"
              >
                <b-img
                  class="box-image2-1"
                  :src="data.item.image_url"
                  fluid
                  @error="handleImageSrc"
                ></b-img>
              </div>
              <div class="d-flex justify-content-center" v-else>
                <video class="box-video" controls>
                  <source :src="data.item.image_url" type="video/mp4" />
                </video>
              </div>
            </template>
            <template v-slot:cell(sortOrder)="data">
              <div>{{ data.item.sortOrder }}</div>
            </template>
            <template v-slot:cell(status)="data">
              <div v-if="data.item.status == 1" class="text-success">
                Active
              </div>
              <div v-else class="text-error">Inactive</div>
            </template>
            <template v-slot:cell(id)="data">
              <div class="d-flex justify-content-center">
                <router-link :to="'/productbanner/details/' + data.item.id">
                  <b-button variant="link" class="text-warning px-1 py-0">
                    <font-awesome-icon icon="pencil-alt" title="Edit" />
                  </b-button>
                </router-link>
                <b-button
                  variant="link"
                  class="text-error px-1 py-0"
                  @click="AlertDelete(data.item.id)"
                >
                  <font-awesome-icon
                    icon="trash-alt"
                    title="Delete"
                    class="main-color"
                  />
                </b-button>
              </div>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
export default {
  name: "ProductBannerIndex",
  components: { HeaderPanel, Pagination },
  data() {
    return {
      fields: [
        {
          key: "image_url",
          label: "Image/Video",
          thStyle: { width: "22%" },
          tdClass: "p-3",
        },
        {
          key: "redirect_type_name",
          label: "Product Banner Type",
          thStyle: { width: "25%" },
          tdClass: "w-150",
        },
        {
          key: "sortOrder",
          label: "Sort Order",
          tdClass: "w-150",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "id",
          label: "Action",
        },
      ],
      items: [],
      isBusy: false,
      checkAll: false,
      selectAllCb: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      filter: {
        level_id: 2,
        take: 10,
        page: 1,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  created: async function () {
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      await this.$store.dispatch("ActionGetBanner", this.filter);
      var data = await this.$store.state.moduleConnectAPI.stateGetBanner;
      if (data.result == 1) {
        this.items = data.detail.detail;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getList();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getList();
    },
    AlertDelete(id) {
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteData(id);
        }
      });
    },
    deleteData: async function (id) {
      this.isLoadingData = true;
      await this.$store.dispatch("ActionDeleteBanner", id);
      var data = await this.$store.state.moduleConnectAPI.stateDeleteBanner;
      if (data.result == 1) {
        this.isLoadingData = false;
        this.successAlert().then(() => {
          this.getList();
        });
      }
    },
  },
};
</script>

<style scoped>
.box-video::before {
  padding-top: 42.9%;
}
</style>
